const countries = [
  "India",
  "China",
  "Singapore",
  "Malaysia",
  "Hongkong",
  "Taiwan",
  "Thailand",
  "Iran",
  "UAE",
  "Bahrain",
  "Kuwait",
  "Oman",
  "KSA",
  "Qatar",
  "UK",
  "USA",
].map((country) => ({ value: country, label: country }));

export default countries;
