const halqa = [
  "Bhatkal Halqa 1",
  "Bhatkal Halqa 2",
  "Bhatkal Halqa 3",
  "Bhatkal Halqa 4",
  "Bhatkal Halqa 5",
  "Bhatkal Halqa 6",
  "Bhatkal Halqa 7",
  "Bhatkal Halqa 8",
  "Bhatkal Halqa 9",
  "Bhatkal Halqa 10",
  "Bhatkal Halqa 11",
  "Bhatkal Halqa 12",
  "Bhatkal Halqa 13",
  "Mangalore Halqa 1",
  "Bangalore Halqa 2",
  "Kolkata Halqa 3",
  "Mumbai Halqa 4",
  "Chennai Halqa 5",
  "Andhra Pradesh Halqa 6",
  "Kerala Halqa 7",
  "Dubai Halqa 1",
  "Riyadh Halqa 2",
  "Yanbu Halqa 3",
  "Abu Dhabi Halqa 4",
  "Muscat Halqa 5",
  "Qatar Halqa 6",
  "Kuwait Halqa 7",
  "Bahrain Halqa 8",
  "Jeddah Halqa 9",
  "Dammam Halqa 10",
].map((halqa) => ({ value: halqa, label: halqa }));

export default halqa;
